import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useMutation } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'

import { Page } from '../../../containers/Page'
import { useStores } from '../../../stores'
import { Severity } from '../../../stores/authStore'
import { AuthAlert } from '../../auth/AuthAlert'
import { AuthFooter } from '../../auth/AuthFooter'
import { AuthHeader } from '../../auth/AuthHeader'
import { PrimaryButton } from '../../common/PrimaryButton'

import styles from './MultiFactorAuthenticationPage.scss'
import { useFragment, useQuery } from 'relay-hooks'
import {
  MfaType,
  MultiFactorAuthenticationPageLoginMutation,
} from '../../../generated/MultiFactorAuthenticationPageLoginMutation.graphql'
import { MultiFactorAuthenticationPageQuery } from '../../../generated/MultiFactorAuthenticationPageQuery.graphql'
import { SecondaryButton } from '../../common/SecondaryButton'
import { MultiFactorAuthenticationPageSendMfaTokenMutation } from '../../../generated/MultiFactorAuthenticationPageSendMfaTokenMutation.graphql'
import { MultiFactorAuthenticationPage_user$key } from '../../../generated/MultiFactorAuthenticationPage_user.graphql'
import { TertiaryButton } from '../../common/TertiaryButton'
import { useEnvironment } from '../../../App'

interface MultiFactorAuthenticationPageProps {
  user?: MultiFactorAuthenticationPage_user$key | null
}

export const MultiFactorAuthenticationPage = observer(
  function MultiFactorAuthenticationPage(
    props: MultiFactorAuthenticationPageProps
  ) {
    const { commonStore, authStore } = useStores()
    const [mfaType, setMfaType] = useState<MfaType>('GOOGLE')
    const [token, setToken] = useState('')
    const [secret, setSecret] = useState('')
    const [submittable, setSubmittable] = useState(false)
    const { mfa } = useEnvironment()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const to =
      params.get('to') ??
      (location.state && 'to' in location.state ? location.state.to : null)
    const activateMfa =
      params.get('activate') === 'true' ||
      (location.state && 'activate' in location.state
        ? location.state.activate
        : null)
    const resendEmailTimeout = 30
    const [resendEmail, setResendEmail] = useState(0)

    const user = useFragment(
      graphql`
        fragment MultiFactorAuthenticationPage_user on AuthenticatedUser {
          mfaType
        }
      `,
      props.user || null
    )

    const mfaQrCode = useQuery<MultiFactorAuthenticationPageQuery>(
      graphql`
        query MultiFactorAuthenticationPageQuery {
          mfaQrCode {
            secret
            qrCodeInline
          }
        }
      `,
      {},
      {
        fetchPolicy: 'store-and-network',
      }
    )

    const [sendMfaToken] =
      useMutation<MultiFactorAuthenticationPageSendMfaTokenMutation>(
        graphql`
          mutation MultiFactorAuthenticationPageSendMfaTokenMutation {
            sendMfaToken {
              message
              success
            }
          }
        `
      )

    const requestEmailMfaToken = useCallback((): void => {
      if (resendEmail === 0 && mfa.emailEnabled) {
        sendMfaToken({ variables: {} }).then((response) => {
          if (response.sendMfaToken.message) {
            authStore.setAuthMessage(
              response.sendMfaToken.message,
              Severity.ERROR
            )
          }
        })

        setResendEmail(resendEmailTimeout)
      }
    }, [authStore, mfa.emailEnabled, resendEmail, sendMfaToken])

    useEffect(() => {
      if (resendEmail > 0) {
        setTimeout(() => setResendEmail(resendEmail - 1), 1000)
      }
    }, [resendEmail])

    useEffect(() => {
      setSubmittable(token !== '')
    }, [token])

    useEffect(() => {
      if (mfaQrCode.data) {
        setSecret(mfaQrCode.data?.mfaQrCode.secret)
      }
    }, [mfaQrCode, activateMfa])

    useEffect(() => {
      const oldNavBarHidden = commonStore.navBarHidden
      if (to?.startsWith('/cms')) {
        commonStore.setNavBarHidden(true)
      }

      return () => {
        commonStore.setNavBarHidden(oldNavBarHidden)
      }
    }, [commonStore, to])

    useEffect(() => {
      if (user) {
        setMfaType(
          user.mfaType === 'EMAIL' && mfa.emailEnabled ? 'EMAIL' : 'GOOGLE'
        )

        if (user.mfaType === 'EMAIL') {
          requestEmailMfaToken()
        }
      }
    }, [user])

    const [validate] = useMutation<MultiFactorAuthenticationPageLoginMutation>(
      graphql`
        mutation MultiFactorAuthenticationPageLoginMutation(
          $mfaType: MfaType!
          $secret: String!
          $token: String!
        ) {
          validateMfa(mfaType: $mfaType, secret: $secret, token: $token) {
            message
            success
          }
        }
      `,
      {
        variables: {
          mfaType: mfaType,
          secret:
            activateMfa || (user?.mfaType === 'EMAIL' && !mfa.emailEnabled)
              ? secret
              : '',
          token: token,
        },
      }
    )

    const onSubmitClicked = useCallback(
      (event: FormEvent): void => {
        event.preventDefault()

        setSubmittable(false)

        validate({
          variables: {
            mfaType: mfaType,
            secret:
              activateMfa || (user?.mfaType === 'EMAIL' && !mfa.emailEnabled)
                ? secret
                : '',
            token: token,
          },
        }).then((response) => {
          if (response.validateMfa.success) {
            if (to) {
              window.location.pathname = to
            } else {
              window.location.pathname = '/'
            }
          } else {
            if (response.validateMfa.message) {
              authStore.setAuthMessage(
                response.validateMfa.message,
                Severity.ERROR
              )
            }
          }
        })
      },
      [validate, mfaType, activateMfa, secret, token, to, authStore]
    )

    const setValidationType = useCallback(
      (newMfaType: MfaType, e): void => {
        e.preventDefault()

        setMfaType(newMfaType)
        setToken('')

        if (newMfaType === 'EMAIL') {
          requestEmailMfaToken()
        }
      },
      [requestEmailMfaToken]
    )

    const { t } = useTranslation()

    const query = new URLSearchParams(location.search)
    const errorMessage = query.get('message')

    // Set an auth error message if we received one.
    useEffect(() => {
      if (errorMessage) {
        authStore.setAuthMessage(errorMessage, Severity.ERROR)
      }
    }, [authStore, errorMessage])

    return (
      <>
        <AuthHeader />

        <Page hideWatermark narrow>
          <div className={styles.formHeader}>
            <div className={styles.head}>
              {activateMfa || (user?.mfaType === 'EMAIL' && !mfa.emailEnabled)
                ? t('auth.header.activateMfa')
                : t('auth.header.mfa')}
            </div>
            <div className={styles.sub}>
              {activateMfa || (user?.mfaType === 'EMAIL' && !mfa.emailEnabled)
                ? t(
                    'auth.action.activate' +
                      (mfaType === 'EMAIL' && mfa.emailEnabled
                        ? 'Email'
                        : 'Google') +
                      'Mfa'
                  )
                : t(
                    'auth.action.' +
                      (mfaType === 'EMAIL' && mfa.emailEnabled
                        ? 'email'
                        : 'google') +
                      'Mfa'
                  )}
            </div>
          </div>

          <form
            method='post'
            action='#'
            className={styles.formContainer}
            onSubmit={onSubmitClicked}
          >
            <AuthAlert />

            {(activateMfa ||
              (user?.mfaType === 'EMAIL' && !mfa.emailEnabled)) &&
              mfaType === 'GOOGLE' &&
              mfaQrCode.data && (
                <>
                  <div
                    className={styles.formGroup}
                    style={{ textAlign: 'center' }}
                  >
                    <img
                      src={mfaQrCode.data?.mfaQrCode.qrCodeInline}
                      alt='MFA QR Code'
                    />
                  </div>

                  <div
                    className={styles.formGroup}
                    style={{ textAlign: 'center' }}
                  >
                    <div>
                      {t('auth.field.mfaSecretKey', {
                        secret: mfaQrCode.data?.mfaQrCode.secret,
                      })}
                    </div>
                  </div>
                </>
              )}

            <div className={styles.formGroup + ' ' + styles.prepend}>
              <div className={styles.prependIcon}>
                <FontAwesomeIcon icon={faLock} />
              </div>
              <input
                autoFocus
                autoComplete='off'
                className={styles.formControl + ' ' + styles.prependPadding}
                placeholder={t('auth.field.mfaToken')}
                name='mfa_token'
                onChange={(e) => {
                  setToken(e.target.value)
                }}
                type='text'
              />
            </div>

            <div className={styles.formFooter}>
              <div>
                <PrimaryButton
                  disabled={!submittable}
                  className={styles.primaryButton}
                  type='submit'
                >
                  {t('auth.action.validate')}
                </PrimaryButton>
                {mfaType === 'EMAIL' && mfa.emailEnabled && (
                  <SecondaryButton
                    disabled={!(resendEmail === 0)}
                    className={styles.secondaryResend}
                    onClick={requestEmailMfaToken}
                  >
                    {t('auth.action.mfaResendEmail', {
                      seconds: resendEmail > 0 ? ' (' + resendEmail + ')' : '',
                    })}
                  </SecondaryButton>
                )}
              </div>

              {(activateMfa || mfaType === 'GOOGLE') && mfa.emailEnabled && (
                <div>
                  <TertiaryButton
                    className={styles.secondaryButton}
                    onClick={(e) =>
                      setValidationType(
                        mfaType === 'EMAIL' && mfa.emailEnabled
                          ? 'GOOGLE'
                          : 'EMAIL',
                        e
                      )
                    }
                  >
                    {t(
                      'auth.action.mfa' +
                        (mfaType === 'EMAIL' && mfa.emailEnabled
                          ? 'App'
                          : 'Email') +
                        'Validation'
                    )}
                  </TertiaryButton>
                </div>
              )}
            </div>
          </form>
        </Page>

        <AuthFooter />
      </>
    )
  }
)
