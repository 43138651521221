/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MfaType = "EMAIL" | "GOOGLE" | "NONE";
export type MultiFactorAuthenticationPage_user = {
    readonly mfaType: MfaType;
    readonly " $refType": "MultiFactorAuthenticationPage_user";
};
export type MultiFactorAuthenticationPage_user$data = MultiFactorAuthenticationPage_user;
export type MultiFactorAuthenticationPage_user$key = {
    readonly " $data"?: MultiFactorAuthenticationPage_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MultiFactorAuthenticationPage_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MultiFactorAuthenticationPage_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mfaType",
            "storageKey": null
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = '6fbf258f9c2e63a83db310ee04ac0359';
export default node;
