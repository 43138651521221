/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MfaType = "EMAIL" | "GOOGLE" | "NONE";
export type MultiFactorAuthenticationPageLoginMutationVariables = {
    mfaType: MfaType;
    secret: string;
    token: string;
};
export type MultiFactorAuthenticationPageLoginMutationResponse = {
    readonly validateMfa: {
        readonly message: string;
        readonly success: boolean;
    };
};
export type MultiFactorAuthenticationPageLoginMutation = {
    readonly response: MultiFactorAuthenticationPageLoginMutationResponse;
    readonly variables: MultiFactorAuthenticationPageLoginMutationVariables;
};



/*
mutation MultiFactorAuthenticationPageLoginMutation(
  $mfaType: MfaType!
  $secret: String!
  $token: String!
) {
  validateMfa(mfaType: $mfaType, secret: $secret, token: $token) {
    message
    success
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "mfaType"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "secret"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "token"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "mfaType",
                    "variableName": "mfaType"
                },
                {
                    "kind": "Variable",
                    "name": "secret",
                    "variableName": "secret"
                },
                {
                    "kind": "Variable",
                    "name": "token",
                    "variableName": "token"
                }
            ],
            "concreteType": "Result",
            "kind": "LinkedField",
            "name": "validateMfa",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "MultiFactorAuthenticationPageLoginMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "MultiFactorAuthenticationPageLoginMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "8bab091d9cc34810100e9286880bc9b9",
            "id": null,
            "metadata": {},
            "name": "MultiFactorAuthenticationPageLoginMutation",
            "operationKind": "mutation",
            "text": "mutation MultiFactorAuthenticationPageLoginMutation(\n  $mfaType: MfaType!\n  $secret: String!\n  $token: String!\n) {\n  validateMfa(mfaType: $mfaType, secret: $secret, token: $token) {\n    message\n    success\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '385f86e2ffea71a0f2e5d8e79438cfae';
export default node;
