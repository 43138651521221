/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MultiFactorAuthenticationPageQueryVariables = {};
export type MultiFactorAuthenticationPageQueryResponse = {
    readonly mfaQrCode: {
        readonly secret: string;
        readonly qrCodeInline: string;
    };
};
export type MultiFactorAuthenticationPageQuery = {
    readonly response: MultiFactorAuthenticationPageQueryResponse;
    readonly variables: MultiFactorAuthenticationPageQueryVariables;
};



/*
query MultiFactorAuthenticationPageQuery {
  mfaQrCode {
    secret
    qrCodeInline
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "MfaQrCode",
            "kind": "LinkedField",
            "name": "mfaQrCode",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secret",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qrCodeInline",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "MultiFactorAuthenticationPageQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "MultiFactorAuthenticationPageQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "540c1865320f6bed96b1d02f2931c4b5",
            "id": null,
            "metadata": {},
            "name": "MultiFactorAuthenticationPageQuery",
            "operationKind": "query",
            "text": "query MultiFactorAuthenticationPageQuery {\n  mfaQrCode {\n    secret\n    qrCodeInline\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '004ea5d223fd70c727a670f86a3c2d45';
export default node;
