/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MultiFactorAuthenticationPageSendMfaTokenMutationVariables = {};
export type MultiFactorAuthenticationPageSendMfaTokenMutationResponse = {
    readonly sendMfaToken: {
        readonly message: string;
        readonly success: boolean;
    };
};
export type MultiFactorAuthenticationPageSendMfaTokenMutation = {
    readonly response: MultiFactorAuthenticationPageSendMfaTokenMutationResponse;
    readonly variables: MultiFactorAuthenticationPageSendMfaTokenMutationVariables;
};



/*
mutation MultiFactorAuthenticationPageSendMfaTokenMutation {
  sendMfaToken {
    message
    success
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Result",
            "kind": "LinkedField",
            "name": "sendMfaToken",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "MultiFactorAuthenticationPageSendMfaTokenMutation",
            "selections": (v0 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "MultiFactorAuthenticationPageSendMfaTokenMutation",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "afdf0ad7651afbf8b4e150cb59b5be74",
            "id": null,
            "metadata": {},
            "name": "MultiFactorAuthenticationPageSendMfaTokenMutation",
            "operationKind": "mutation",
            "text": "mutation MultiFactorAuthenticationPageSendMfaTokenMutation {\n  sendMfaToken {\n    message\n    success\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '020696b87dd91b19db6dd9522e4ef4f6';
export default node;
