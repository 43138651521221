/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RoutingAppRoutes_user = {
    readonly hasMfaActivated: boolean;
    readonly isOnboarding: boolean;
    readonly isMfaAuthenticated: boolean;
    readonly " $fragmentRefs": FragmentRefs<"RoutingOnlyGuests_user" | "RoutingRequireAuthentication_user" | "MultiFactorAuthenticationPage_user">;
    readonly " $refType": "RoutingAppRoutes_user";
};
export type RoutingAppRoutes_user$data = RoutingAppRoutes_user;
export type RoutingAppRoutes_user$key = {
    readonly " $data"?: RoutingAppRoutes_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RoutingAppRoutes_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoutingAppRoutes_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasMfaActivated",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnboarding",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMfaAuthenticated",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoutingOnlyGuests_user"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoutingRequireAuthentication_user"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MultiFactorAuthenticationPage_user"
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = 'be15af101fbefc0a5a5da2f6557d4f1a';
export default node;
