/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AnonymousDuellingOption = "ALL" | "OFF" | "USER";
export type MfaAppSetting = "DISABLED" | "OPTIONAL" | "REQUIRED";
export type MfaCmsSetting = "OPTIONAL" | "REQUIRED";
export type ProductTypeInclusive = "BOTH" | "ONFIRE" | "POWERAPP";
export type AppDataQueryVariables = {};
export type AppDataQueryResponse = {
    readonly me: {
        readonly __typename: string;
        readonly id: string;
        readonly email: string;
        readonly language: string;
    } | null;
    readonly settings: {
        readonly appLogos: {
            readonly fullColorLogoUrl: string | null;
            readonly whiteLogoUrl: string | null;
        };
        readonly backgroundImageUrl: string | null;
        readonly customUpToDateScoreThreshold: boolean | null;
        readonly editableTranslations: ReadonlyArray<{
            readonly language: string;
            readonly translations: ReadonlyArray<{
                readonly key: string;
                readonly value: string;
            }> | null;
        }>;
        readonly language: string;
        readonly loginWithCompanyUserId: boolean;
        readonly organization: string;
        readonly organizationLogoUrl: string | null;
        readonly organizationWatermarkLogoUrl: string | null;
        readonly pageTitle: string;
        readonly product: ProductTypeInclusive;
        readonly selfActivateWithCorporateId: boolean;
        readonly showScore: boolean;
        readonly showTopicScore: boolean;
        readonly hidePracticeAllButton: boolean;
        readonly singleSignOn: {
            readonly idpOnly: boolean;
            readonly idps: ReadonlyArray<{
                readonly description: ReadonlyArray<{
                    readonly language: string;
                    readonly value: string;
                }> | null;
                readonly displayName: ReadonlyArray<{
                    readonly language: string;
                    readonly value: string;
                }>;
                readonly isHybrid: boolean;
                readonly loginUrl: string;
                readonly name: string;
                readonly setPasswordInstruction: ReadonlyArray<{
                    readonly language: string;
                    readonly value: string;
                }> | null;
            }>;
        };
        readonly supportUrl: string;
        readonly onFireSupportUrl: string;
        readonly privacyPolicyUrl: string;
        readonly anonymousDuelling: AnonymousDuellingOption;
        readonly mfa: {
            readonly enabled: boolean;
            readonly emailEnabled: boolean;
            readonly cms: MfaCmsSetting;
            readonly app: MfaAppSetting;
        };
    };
};
export type AppDataQuery = {
    readonly response: AppDataQueryResponse;
    readonly variables: AppDataQueryVariables;
};



/*
query AppDataQuery {
  me {
    __typename
    id
    email
    language
  }
  settings {
    appLogos {
      fullColorLogoUrl
      whiteLogoUrl
    }
    backgroundImageUrl
    customUpToDateScoreThreshold
    editableTranslations {
      language
      translations {
        key
        value
      }
    }
    language
    loginWithCompanyUserId
    organization
    organizationLogoUrl
    organizationWatermarkLogoUrl
    pageTitle
    product
    selfActivateWithCorporateId
    showScore
    showTopicScore
    hidePracticeAllButton
    singleSignOn {
      idpOnly
      idps {
        description {
          language
          value
        }
        displayName {
          language
          value
        }
        isHybrid
        loginUrl
        name
        setPasswordInstruction {
          language
          value
        }
      }
    }
    supportUrl
    onFireSupportUrl
    privacyPolicyUrl
    anonymousDuelling
    mfa {
      enabled
      emailEnabled
      cms
      app
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    } as any, v2 = [
        (v0 /*: any*/),
        (v1 /*: any*/)
    ], v3 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                },
                (v0 /*: any*/)
            ],
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "concreteType": "EnvironmentSettings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AppLogos",
                    "kind": "LinkedField",
                    "name": "appLogos",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullColorLogoUrl",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "whiteLogoUrl",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "backgroundImageUrl",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customUpToDateScoreThreshold",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EditableTranslations",
                    "kind": "LinkedField",
                    "name": "editableTranslations",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EditableTranslation",
                            "kind": "LinkedField",
                            "name": "translations",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "key",
                                    "storageKey": null
                                },
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v0 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "loginWithCompanyUserId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organization",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organizationLogoUrl",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organizationWatermarkLogoUrl",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pageTitle",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "product",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "selfActivateWithCorporateId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showScore",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showTopicScore",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hidePracticeAllButton",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SsoSettings",
                    "kind": "LinkedField",
                    "name": "singleSignOn",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "idpOnly",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SsoIdpConfiguration",
                            "kind": "LinkedField",
                            "name": "idps",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LocalizedString",
                                    "kind": "LinkedField",
                                    "name": "description",
                                    "plural": true,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LocalizedString",
                                    "kind": "LinkedField",
                                    "name": "displayName",
                                    "plural": true,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isHybrid",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "loginUrl",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LocalizedString",
                                    "kind": "LinkedField",
                                    "name": "setPasswordInstruction",
                                    "plural": true,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supportUrl",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onFireSupportUrl",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privacyPolicyUrl",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "anonymousDuelling",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "MfaSettings",
                    "kind": "LinkedField",
                    "name": "mfa",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emailEnabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cms",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "app",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AppDataQuery",
            "selections": (v3 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AppDataQuery",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "cacheID": "8774e0b1a16ee108b23f2a1a201a7c75",
            "id": null,
            "metadata": {},
            "name": "AppDataQuery",
            "operationKind": "query",
            "text": "query AppDataQuery {\n  me {\n    __typename\n    id\n    email\n    language\n  }\n  settings {\n    appLogos {\n      fullColorLogoUrl\n      whiteLogoUrl\n    }\n    backgroundImageUrl\n    customUpToDateScoreThreshold\n    editableTranslations {\n      language\n      translations {\n        key\n        value\n      }\n    }\n    language\n    loginWithCompanyUserId\n    organization\n    organizationLogoUrl\n    organizationWatermarkLogoUrl\n    pageTitle\n    product\n    selfActivateWithCorporateId\n    showScore\n    showTopicScore\n    hidePracticeAllButton\n    singleSignOn {\n      idpOnly\n      idps {\n        description {\n          language\n          value\n        }\n        displayName {\n          language\n          value\n        }\n        isHybrid\n        loginUrl\n        name\n        setPasswordInstruction {\n          language\n          value\n        }\n      }\n    }\n    supportUrl\n    onFireSupportUrl\n    privacyPolicyUrl\n    anonymousDuelling\n    mfa {\n      enabled\n      emailEnabled\n      cms\n      app\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '47141d1dc3d78c287aa78b80ec5b6002';
export default node;
